import React, { useContext } from "react";
import { SharedState } from "../App";

export const Title = props => {
  const { metadata } = useContext(SharedState);
  const { title } = metadata;
  return (
    <React.Fragment>
      <p className="title" style={{ fontSize: props.size + "vh" }}>
        {title}
      </p>
    </React.Fragment>
  );
};

export const Album = props => {
  const { metadata } = useContext(SharedState);
  const { album } = metadata;
  if ((album && album !== "Unknown Album") || props.showAnyway) {
    return (
      <React.Fragment>
        <p className="album" style={{ fontSize: props.size + "vh" }}>
          {props.pre ? <small>{props.pre}</small> : null}
          {album}
        </p>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <p className="album noalbum" style={{ fontSize: props.size + "vh" }}>
          &nbsp;
        </p>
      </React.Fragment>
    );
  }
};

export const Artist = props => {
  const { metadata } = useContext(SharedState);
  const { artist } = metadata;
  return (
    <React.Fragment>
      <p className="title" style={{ fontSize: props.size + "vh" }}>
        {props.pre && artist ? <small>{props.pre}</small> : null}
        {artist}
      </p>
    </React.Fragment>
  );
};
