import React, { useState } from "react";
import { SettingsButton } from "./Buttons";
import { SettingsDropUp } from "./SettingsDropUp";

export function Settings() {
  const [settingsMenu, setSettingsMenu] = useState(false);
  const handleSettingsClick = () => {
    setSettingsMenu(!settingsMenu);
  };
  return (
    <div className="dropdown">
      <SettingsButton action={handleSettingsClick} />
      {settingsMenu ? <SettingsDropUp blur={setSettingsMenu} /> : null}
    </div>
  );
}
