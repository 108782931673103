import React, { useContext } from "react";
import { SharedState } from "../App";
import { Title, Artist, Album } from "./TextBits";
import { BufferingIcon } from "./Buttons";
import { getOptimaltextSize } from "./functions";

export function NowPlayingDesktop(props) {
  const { metadata } = useContext(SharedState);
  const sizes = getOptimaltextSize(metadata);
  if (metadata.title) {
    return (
      <div className={`nowplayingtext`}>
        <Title size={sizes.titleSize} />
        <Artist pre="by " size={sizes.artistSize} />
        <Album pre="from " size={sizes.albumSize} />
      </div>
    );
  } else {
    return (
      <div className={`nowplayingtext`}>
        <div />
        <div style={{ textAlign: "center", fontSize: "4vh" }}>
          <BufferingIcon />
        </div>
        <div />
      </div>
    );
  }
}
