import React, { useContext } from "react";
import { AlbumArt } from "./AlbumArt";
import { NowPlayingDesktop } from "./NowPlayingDesktop";
import { PlaybackControl } from "./PlaybackControl";
import { Settings } from "./Settings";
import { SharedState } from "../App";
import { isIOS } from "react-device-detect";
import { Title, Artist, Album } from "./TextBits";
import Swipe from "react-easy-swipe";

export function MobileViewHome(props) {
  const { warned, showInfo } = useContext(SharedState);
  return (
    <React.Fragment>
      <div className="logo-mobile" />
      <div className="art-mobile">
        <AlbumArt size={512} />
      </div>

      <Cont />
      <NowPlayingDesktop />
      {isIOS && !warned ? <IOSWarning /> : null}
      {showInfo ? <ShowInfo /> : null}
    </React.Fragment>
  );
}

export function ShowInfo() {
  const { metadata, showInfo, setShowInfo } = useContext(SharedState);
  const handleShowInfo = () => {
    setShowInfo(!showInfo);
  };
  return (
    <Swipe onSwipeDown={handleShowInfo} className="desc">
      <Title />
      <Artist pre="by " />
      <Album pre="from " showAnyway />
      <div style={{ marginTop: "auto" }}>
        {metadata.store && metadata.store !== "none" ? (
          <p>
            Buy this album from{" "}
            <a style={{ color: "white" }} href={metadata.wwwpublisher}>
              {metadata.store}
            </a>
          </p>
        ) : null}
      </div>
    </Swipe>
  );
}

export function IOSWarning(props) {
  const { setWarned } = useContext(SharedState);

  return (
    <div className="ioswarning">
      <h2>Hold up...</h2>
      <p>
        iOS doesn't support our web app, and it's unlikely we'll be able to fix
        that without paying a lot of money to make an app.
      </p>
      <p>We recommend using the TuneIn app instead.</p>
      <a href="https://tunein.com/radio/Fillydelphia-Radio-s165880/">
        <button>Use TuneIn App</button>
      </a>
      <button onClick={() => setWarned(true)}>Use Web App</button>
    </div>
  );
}

export function Cont(props) {
  const { metadata, showInfo, setShowInfo } = useContext(SharedState);
  const { title, artist } = metadata;
  const handleShowInfo = () => {
    setShowInfo(!showInfo);
  };
  return (
    <div className="cont">
      <PlaybackControl />

      <Swipe
        allowMouseEvents={true}
        onSwipeUp={handleShowInfo}
        className="metadata"
        onClick={handleShowInfo}
      >
        <p className="arrow">⌃</p>
        <p>{title}</p>
        <p>{artist}</p>
      </Swipe>
      <Settings />
    </div>
  );
}
