import React, { useState, useEffect } from "react";
import { HomePage } from "./components/HomePage";
import * as firebase from "firebase/app";
import "firebase/auth";
import Sound from "react-sound";
import { BrowserRouter as Router, Route } from "react-router-dom";
import {
  isMobile,
  isIOS,
  isChrome,
  isChromium,
  isAndroid,
} from "react-device-detect";
import "firebase/database";
import "./App.scss";

var config = {
  apiKey: "AIzaSyCkc8MAShqq2IXB7mEJCvwwL4MRVhmrdd0",
  authDomain: "fillydelphia-radio.firebaseapp.com",
  databaseURL: "https://fillydelphia-radio.firebaseio.com",
  projectId: "fillydelphia-radio",
  storageBucket: "fillydelphia-radio.appspot.com",
  messagingSenderId: "404657910771",
  appId: "1:404657910771:web:e73c285b4b420672",
};
firebase.initializeApp(config);
var auth = firebase.auth();
// const messaging = firebase.messaging();

const castAppId = "E5E73985";
var castContext;
var castStateChanged;

if (window.cast) {
  const cast = window.cast;
  castContext = cast.framework.CastContext.getInstance();
  castContext.setOptions({
    receiverApplicationId: castAppId,
    autoJoinPolicy: window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
  });
  castStateChanged = cast.framework.CastContextEventType.CAST_STATE_CHANGED;
}

const realtimedb = firebase
  .database()
  .ref("rest/fillydelphia-radio/now-playing");

export const SharedState = React.createContext(null);

const Proof = () => {
  return (
    <div>
      <p>westj registered vhost on irc.canternet.org </p>
      <p>Fri May 8 2020</p>
      <p>04:10 am</p>
    </div>
  );
};

const Panel = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  function resetPassword(event) {
    event.preventDefault();
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setMessage(`Password for ${email} will be reset`);
      })
      .catch((e) => {
        setMessage(`Password not reset`);
      });
  }

  return (
    <div>
      <form>
        <input
          type="email"
          value={email}
          onChange={(event) => {
            event.preventDefault();
            setEmail(event.target.value);
          }}
        ></input>
        <button onClick={resetPassword}>Reset Password</button>
        <p>{message}</p>
      </form>
    </div>
  );
};

/**
 * App Component
 */
export default function App() {
  const [metadata, setMetadata] = useState({});
  const [streamUrl, setStreamUrl] = useState(
    "https://fillyradio.com/stream-128k"
  );
  const [playStatus, setPlayStatus] = useState("STOPPED");
  const [audioStatus, setAudioStatus] = useState("Stopped");
  const [volume, setVolume] = useState(80);
  const [network, setNetwork] = useState("unknown");
  const [warned, setWarned] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [isCasting, setIsCasting] = useState(false);
  const [castState, setCastState] = useState("");
  const [castRemoteState, setCastRemoteState] = useState();
  const { title, artist, album, covers } = metadata;
  let castSession;
  useEffect(() => {
    startUp(setStreamUrl, setVolume, setMetadata);
  }, [metadata.title]);
  useEffect(() => {
    const cast = window.cast ? window.cast : false;

    if (cast) {
      castContext.addEventListener(castStateChanged, function (event) {
        // Put the state to the global variable/object
        setCastState(event.castState);
        if (event.castState === "CONNECTING") {
          setAudioStatus("Buffering");
        } else if (event.castState === "CONNECTED") {
          // put the castSession to the global variable so we can call it elsewhere
          castSession =
            cast.framework.CastContext.getInstance().getCurrentSession();
          console.log(castSession);

          if (playStatus === "PLAYING") {
            setPlayStatus("STOPPED");
            setAudioStatus("Stopped");
          }
        }
      });
    }
  }, []);
  useEffect(() => {
    // if (playStatus === "PLAYING" || castState === "CONNECTED") {
    //   setIsCasting(true);
    //   var mediaInfo = new window.chrome.cast.media.MediaInfo(
    //     streamUrl,
    //     "audio/mp3"
    //   );
    //   mediaInfo.streamType = window.chrome.cast.media.StreamType.LIVE;
    //   mediaInfo.metadata =
    //     new window.chrome.cast.media.MusicTrackMediaMetadata();
    //   mediaInfo.metadata.title = metadata.track;
    //   mediaInfo.metadata.artist = metadata.artist;
    //   mediaInfo.metadata.albumName = metadata.album;
    //   let coverUrl = metadata.covers["512"];
    //   mediaInfo.metadata.images = [new window.chrome.cast.Image(coverUrl)];
    //   // bundle the above block into a LoadRequest
    //   var request = new window.chrome.cast.media.LoadRequest(mediaInfo);
    //   // Now we tell the Chromecast to load this request. The Chromecast takes
    //   // care of handling the data, we just wait for a promise that says it
    //   // succeeded.
    //   castSession.loadMedia(request).then(
    //     function () {
    //       // We create a playerController here, so we can watch and control the
    //       // player on the Sender app
    //       var player = new window.cast.framework.RemotePlayer();
    //       let playerController =
    //         new window.cast.framework.RemotePlayerController(player);
    //       // We initialize an Event listener, to handle the UI and allow us to
    //       // use the state data elsewhere
    //       playerController.addEventListener(
    //         window.cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
    //         function (event) {
    //           setCastRemoteState(event);
    //         }
    //       );
    //     },
    //     function (errorCode) {
    //       console.log("Error code: " + errorCode);
    //       setIsCasting(false);
    //     }
    //   );
    // }
  });
  useEffect(() => {
    if (audioStatus === "Playing" || audioStatus === "Buffering") {
      document.title = `🎶  ${metadata.title} - ${metadata.artist} - Fillydelphia Radio`;

      if ("mediaSession" in navigator) {
        navigator.mediaSession.metadata = new window.MediaMetadata({
          title,
          artist,
          album,
          artwork: [
            { src: covers[96], sizes: "96x96", type: "image/png" },
            { src: covers[128], sizes: "128x128", type: "image/png" },
            { src: covers[192], sizes: "192x192", type: "image/png" },
            { src: covers[256], sizes: "256x256", type: "image/png" },
            { src: covers[384], sizes: "384x384", type: "image/png" },
            { src: covers[512], sizes: "512x512", type: "image/png" },
          ],
        });
        navigator.mediaSession.setActionHandler("pause", function () {
          // User clicked "Previous Track" media notification icon.
          setPlayStatus("STOPPED");
        });
        navigator.mediaSession.setActionHandler("play", function () {
          // User clicked "Previous Track" media notification icon.
          setPlayStatus("PLAYING");
        });
      }
    } else {
      document.title = "Fillydelphia Radio - Radio for Bronies";
    }
  }, [
    album,
    artist,
    audioStatus,
    covers,
    metadata.artist,
    metadata.title,
    title,
  ]);
  useEffect(() => {
    if ((isChrome || isChromium || isAndroid) && !isIOS) {
      let net = navigator.connection;
      setNetwork(net.type);
      net.onchange = () => {
        if (net.type !== network) {
          setNetwork(net.type);
          if (playStatus === "PLAYING") {
            handlePlaybackError();
          }
        }
      };
    }
  }, [network, playStatus]);
  const setStreamUrlHandler = (url) => {
    localStorage.setItem("url", url);
    setStreamUrl(url);
  };
  const setVolumeHandler = (volume) => {
    localStorage.setItem("volume", volume);
    setVolume(volume);
  };
  const handlePlaybackError = () => {
    setPlayStatus("STOPPED");
    setAudioStatus("Buffering");
    setPlayStatus("PLAYING");
  };
  return (
    <React.Fragment>
      <SharedState.Provider
        value={{
          warned,
          setWarned,
          metadata,
          streamUrl,
          setStreamUrlHandler,
          playStatus,
          setPlayStatus,
          volume,
          setVolumeHandler,
          audioStatus,
          setAudioStatus,
          showInfo,
          setShowInfo,
        }}
      >
        {playStatus === "PLAYING" && !isCasting ? (
          <Sound
            url={streamUrl}
            playStatus={playStatus}
            volume={volume}
            onPlaying={() => setAudioStatus("Playing")}
            onStop={() => setAudioStatus("Stopped")}
            onError={() => handlePlaybackError()}
            onPause={() => alert("HALT")}
          />
        ) : null}
        <Router>
          <Route path="/" exact component={HomePage} />
          <Route path="/panel/" exact component={Panel} />
          <Route path="/verify/" exact component={Proof} />
        </Router>
      </SharedState.Provider>
    </React.Fragment>
  );
}

/**
 * Sets volume and streamURL from previous session
 * Subscribes to Realtime Database
 * @param {function} setStreamUrl
 * @param {function} setVolume
 * @param {function} setMetadata
 */
function startUp(setStreamUrl, setVolume, setMetadata) {
  if (!localStorage.getItem("url")) {
    localStorage.setItem("url", "https://fillyradio.com/stream-128k");
  } else {
    setStreamUrl(localStorage.getItem("url"));
  }
  if (!localStorage.getItem("volume")) {
    if (isMobile) {
      localStorage.setItem("volume", "100");
      setVolume(100);
    }
    localStorage.setItem("volume", "80");
  } else {
    setVolume(localStorage.getItem("volume"));
    // eslint-disable-next-line no-unused-expressions
    isMobile ? setVolume(100) : null;
  }
  realtimedb.on("value", function (snapshot) {
    if (snapshot.val().title) {
      setMetadata(snapshot.val());
    }
  });
}
