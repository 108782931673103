import React from "react";
import { DiscordButton, FacebookButton } from "./Buttons";

export const Nav = (props) => {
  return (
    <div className="nav">
      <ul>
        <li>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://fillyradio.com/discord"
          >
            <DiscordButton />
          </a>
        </li>
        <li>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.facebook.com/FillydelphiaRadio/"
          >
            <FacebookButton />
          </a>
        </li>
      </ul>
    </div>
  );
};
