import React, { useContext } from "react";
import { SharedState } from "../App";
import { PlayButton, StopButton, BufferingIcon } from "./Buttons";

/**
 * Shows the Playback Control. Play, Buffering, Pause... that thing
 * @param {props} props
 */

export function PlaybackControl(props) {
  const { audioStatus, setAudioStatus, playStatus, setPlayStatus, metadata } =
    useContext(SharedState);
  const togglePlayStatus = () => {
    if (playStatus === "PLAYING") {
      setPlayStatus("STOPPED");
    } else if (playStatus === "STOPPED" && metadata.title) {
      setPlayStatus("PLAYING");
      setAudioStatus("Buffering");
    }
  };
  return (
    <React.Fragment>
      {audioStatus === "Stopped" &&
      playStatus !== "PLAYING" &&
      (metadata.title || metadata.artist) ? (
        <PlayButton action={() => togglePlayStatus()} />
      ) : null}
      {audioStatus === "Playing" ? (
        <StopButton action={() => togglePlayStatus()} />
      ) : null}
      {audioStatus === "Buffering" || (!metadata.title && !metadata.artist) ? (
        <BufferingIcon />
      ) : null}
      {audioStatus === "Stopped" && playStatus === "PLAYING" ? (
        <BufferingIcon />
      ) : null}
    </React.Fragment>
  );
}
