export function CalculateTimeSince(history, props, minutesSince) {
  const timePlayed = new Date(history[props.number].on_air + " GMT+0000");
  const timeSince = Date.now() - new Date(timePlayed);
  minutesSince = Math.round(timeSince / 60000);
  return minutesSince;
}

export function getOptimaltextSize(metadata) {
  let titleSize = 4,
    albumSize = 4,
    artistSize = 4;
  if (metadata.title) {
    if (metadata.title.length > 35) {
      titleSize = 4.5;
    } else if (metadata.title.length > 20) {
      titleSize = 5.5;
    } else if (metadata.title.length > 16) {
      titleSize = 6;
    } else if (metadata.title.length > 13) {
      titleSize = 7;
    } else {
      titleSize = 8;
    }
  }
  if (metadata.artist) {
    if (metadata.artist.length > 35) {
      artistSize = 3.5;
    } else if (metadata.artist.length > 20) {
      artistSize = 4;
    } else if (metadata.artist.length > 16) {
      artistSize = 4.5;
    } else if (metadata.artist.length > 13) {
      artistSize = 5;
    } else {
      artistSize = 5.5;
    }
  }

  if (metadata.album) {
    if (metadata.album.length > 35) {
      albumSize = 2.5;
    } else if (metadata.album.length > 20) {
      albumSize = 3;
    } else if (metadata.album.length > 16) {
      albumSize = 3.5;
    } else if (metadata.album.length > 13) {
      albumSize = 4;
    } else {
      albumSize = 4.5;
    }
  }

  return { titleSize, albumSize, artistSize };
}
