import React, { useContext } from "react";
import { SharedState } from "../App";
import { More } from "./More";
import { CalculateTimeSince } from "./functions";

export function PreviouslyPlayedSingle(props) {
  const { metadata } = useContext(SharedState);
  const { history } = metadata;
  const style = {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    margin: "0.5vh",
    fontSize: "1.5vh",
  };
  let minutesSince = 0;
  if (history[props.number].on_air) {
    minutesSince = CalculateTimeSince(history, props, minutesSince);
  }

  return (
    <React.Fragment>
      <div className={`played-${props.number}`}>
        <img src={history[props.number].covers[96]} alt="Past Cover 1" />

        <div className="text">
          <p style={style}>{history[props.number].title}</p>
          <p style={style}>{history[props.number].artist}</p>
          <p style={style}>{history[props.number].album}</p>
          {minutesSince ? (
            <p style={style}>Played {minutesSince} minutes ago</p>
          ) : null}
        </div>
      </div>
      <div className={`option-${props.number}`}>
        <More size={32} number={props.number} />
      </div>
    </React.Fragment>
  );
}
