import React, { useContext, useEffect } from "react";
import { SharedState } from "../App";

/**
 * The panel that shows when you click the Settings button in `.controls`. Has links to the M3U files, and sets the player quality.
 * @param {props} props
 */

export function SettingsDropUp(props) {
  const { streamUrl, setStreamUrlHandler } = useContext(SharedState);
  var timeOutId = null;
  const style = { border: "black solid 2px" };
  const setQuality = (q) => {
    if (q === 64) {
      setStreamUrlHandler("https://fillyradio.com/stream-64k");
    } else if (q === 128) {
      setStreamUrlHandler("https://fillyradio.com/stream-128k");
    } else if (q === 320) {
      setStreamUrlHandler("https://fillyradio.com/stream-320k");
    } else if (q === 8) {
      setStreamUrlHandler("https://fillyradio.com/stream-hqogg");
    } else if (q === 1) {
      setStreamUrlHandler(
        "https://firebasestorage.googleapis.com/v0/b/fillydelphia-radio.appspot.com/o/Fillydelphia-Radio-MP3-320k.m3u?alt=media&token=225f03ea-7d64-4a5d-b748-f5c35f94258f"
      );
    }
  };
  let menuBox = React.createRef();

  useEffect(() => {
    menuBox.current.focus();
  });

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      props.blur(false);
    });
  };

  const onFocusHandler = () => {
    clearTimeout(timeOutId);
  };

  return (
    <div
      className={`dropdown-content show`}
      tabIndex="0"
      ref={menuBox}
      onBlur={onBlurHandler}
      onFocus={onFocusHandler}
    >
      <p>Quality</p>
      <hr />
      <div
        style={streamUrl === "https://fillyradio.com/stream-64k" ? style : null}
        onClick={() => setQuality(64)}
        onFocus={onFocusHandler}
      >
        <p>AAC+ 64k</p>
      </div>
      <div
        style={
          streamUrl === "https://fillyradio.com/stream-128k" ? style : null
        }
        onClick={() => setQuality(128)}
        onFocus={onFocusHandler}
      >
        <p>MP3 128k</p>
      </div>
      <div
        style={
          streamUrl === "https://fillyradio.com/stream-320k" ? style : null
        }
        onClick={() => setQuality(320)}
        onFocus={onFocusHandler}
      >
        <p>MP3 320k</p>
      </div>
      <div
        style={
          streamUrl === "https://fillyradio.com/stream-hqogg" ? style : null
        }
        onClick={() => setQuality(8)}
        onFocus={onFocusHandler}
      >
        <p>HQ Ogg Vorbis</p>
      </div>
      <div
        style={
          streamUrl ===
          "https://firebasestorage.googleapis.com/v0/b/fillydelphia-radio.appspot.com/o/Fillydelphia-Radio-MP3-320k.m3u?alt=media&token=225f03ea-7d64-4a5d-b748-f5c35f94258f"
            ? style
            : null
        }
        onClick={() => setQuality(1)}
        onFocus={onFocusHandler}
      >
        <p>M3U Debug for Safari</p>
      </div>
      <hr />
      <p>External Player</p>
      <hr />
      <div onFocus={onFocusHandler}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          download
          href="https://firebasestorage.googleapis.com/v0/b/fillydelphia-radio.appspot.com/o/Fillydelphia-Radio-AAC-64k.m3u?alt=media&token=d9d4b5e1-92f9-4eff-943b-ef9b899e7236"
        >
          64k AAC
        </a>
      </div>
      <div onFocus={onFocusHandler}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          download
          href="https://firebasestorage.googleapis.com/v0/b/fillydelphia-radio.appspot.com/o/Fillydelphia-Radio-MP3-128k.m3u?alt=media&token=f668a081-a3cb-4a65-8cb7-9407135fe08b"
        >
          128k MP3
        </a>
      </div>
      <div onFocus={onFocusHandler}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          download
          href="https://firebasestorage.googleapis.com/v0/b/fillydelphia-radio.appspot.com/o/Fillydelphia-Radio-MP3-320k.m3u?alt=media&token=225f03ea-7d64-4a5d-b748-f5c35f94258f"
        >
          320k MP3
        </a>
      </div>
    </div>
  );
}
