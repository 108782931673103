import React, { useState, useContext, useEffect } from "react";
import { SharedState } from "../App";
import { BuyButton } from "./Buttons";
import { MoreDropUp } from "./MoreDropUp";

export function More(props) {
  const [moreMenu, setMoreMenu] = useState(false);
  const [buyUrl, setBuyUrl] = useState("null");
  const [store, setStore] = useState("loading");
  const num = props.number;
  const { metadata } = useContext(SharedState);
  const { history } = metadata;

  const handleMoreClick = () => {
    setMoreMenu(!moreMenu);
  };

  useEffect(() => {
    if (history) {
      if (history[num]) {
        if (history[num].store) {
          if (history[num].store !== "none") {
            setBuyUrl(history[num].wwwpublisher);
            setStore(history[num].store);
          } else {
            setStore(false);
          }
        }
      }
    }
  }, [history, num]);

  return (
    <div className="dropdown" style={{ marginLeft: "auto" }}>
      <BuyButton size={props.size} action={handleMoreClick} />
      {moreMenu ? (
        <MoreDropUp
          buyUrl={buyUrl}
          store={store}
          number={props.number}
          blur={setMoreMenu}
        />
      ) : null}
    </div>
  );
}
