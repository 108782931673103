import React, { useContext } from "react";
import { SharedState } from "../App";

export const LivePanel = (props) => {
  const { metadata } = useContext(SharedState);
  return (
    // {
    //   metadata.icename ?
    //   metadata.icename ?
    //   metadata.icename ?
    <div className="banner">
      <p className="djname">
        Live On Air: {metadata.icename ? metadata.icename : "Live DJ"}
      </p>
    </div>
    // }
  );
};
