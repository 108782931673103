import React, { useEffect } from "react";
import { Bandcamp } from "./Buttons";

export function MoreDropUp(props) {
  const { store, buyUrl } = props;

  let timeOutId = null;

  let menuBox = React.createRef();

  useEffect(() => {
    menuBox.current.focus();
  });

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      props.blur(false);
    });
  };

  const onFocusHandler = () => {
    clearTimeout(timeOutId);
  };

  return (
    <div
      className={`dropdown-content show`}
      tabIndex="0"
      ref={menuBox}
      onBlur={onBlurHandler}
      onFocus={onFocusHandler}
      style={props.size ? { bottom: 0 + "px" } : null}
    >
      <ul onFocus={onFocusHandler}>
        {buyUrl && store ? (
          <a
            href={buyUrl}
            onFocus={onFocusHandler}
            target="_blank"
            rel="noopener noreferrer"
          >
            <li style={{ whiteSpace: "nowrap" }}>
              {store === "Bandcamp" ? <Bandcamp size={1.4} /> : null} {store}
            </li>
          </a>
        ) : (
          <li style={{ whiteSpace: "nowrap" }}>
            <em>No purchase link ☹</em>
          </li>
        )}
      </ul>
    </div>
  );
}
