import React, { useContext } from "react";
import { SharedState } from "../App";
import { VolumeDownIcon, VolumeUpIcon } from "./Buttons";

/**
 * Shows the Volume Control and.. uh... controls it...
 * @param {props} props
 */

export function VolumeSlider(props) {
  const { volume, setVolumeHandler } = useContext(SharedState);
  const handleVolumeChange = (e) => {
    setVolumeHandler(e.target.value);
  };
  const handleVolumeClick = (e) => {
    if (e === 0) {
      if (volume > 10) {
        setVolumeHandler(volume - 10);
      } else setVolumeHandler(0);
    } else if (e === 1) {
      if (volume < 90) {
        setVolumeHandler(volume + 10);
      } else setVolumeHandler(100);
    }
  };
  return (
    <div>
      <VolumeDownIcon size={24} action={() => handleVolumeClick(0)} />
      <input
        type="range"
        value={volume}
        onChange={handleVolumeChange}
        onMouseUp={handleVolumeChange}
        min="0"
        max="100"
        step="1"
      />
      &nbsp;
      <VolumeUpIcon size={24} action={() => handleVolumeClick(1)} />
    </div>
  );
}
