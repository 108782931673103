import React from "react";

export const PlayButton = props => {
  return (
    <input
      style={{ width: props.size + "px" }}
      onClick={props.action}
      type="image"
      alt="play"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAADmwAAA5sBPN8HMQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAG1SURBVGiB7dnPKwRhHMfx76BQRJETuVFSkpNcuLg5uq6/AEeOjq7+hT26Kg5KSZuLUhxc5ERRRIlaWm+HZ5+ydnd2fn93pnmd5/B5r2nnmSWSy+VyfwHbQLf2jtAw7oGC9pZQqHUCTGtvCoR638AeMKC9zZcGIdYzsAl0aG/0xCXEugDmtXe25CEE4AcoAiPae5vyGGK9Vm+3Lu3ddXyGWDfAsvb2GgFDrANgXLtBREKHAHwAO0BP2kOsW2A1CyHWMTCVhRCAL8zpoD/tIdYDUACctIdYp8BMFkIAKpjTwXDaQ6wXzOmg08s+z/ckQPCPIZRLEVl3HKfkdlEajt6zInIG7ANjzS5KQ4iIuXNc74j2O53WOxeRDcdxLtwuaue/yJOIrInIQqsIXxL8tioDu8BgZOMVQo6AyVgCEgq5A1ZiDYg55BPzjtKbSERMIUVgNLGAGEKugaXEAyIMeaMdflkJEVDBvDwNqQZYASNKwJz29ho+Ax5J6o3PL48BZczXaZ/23qY8RBwCE9o7W3IJSO6pHIUGAe/AFkk+laPwL0LnqRyFasAVsKi9JRSy8u/pXC6Xbr9ssHrlxy2T1wAAAABJRU5ErkJggg=="
    />
  );
};

export const BufferingIcon = props => {
  return (
    <input
      style={{ width: props.size + "px" }}
      type="image"
      alt="buffering"
      className="spinme"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAADmwAAA5sBPN8HMQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAKgSURBVGiB7ZhNbA1RGIbf45+kFiKlKixU/ESphbTCqtIEESIqIhaNRSNigTUSib0FGxsbsaiNvTRhU4mFIH5iwUZEJaioqvTv3sdipumYzsydO/fMHT/nWU1mzrzf+805c853juRwOByOfw3gKPAaeAv0Fu0nE8BqYJwZSsDWvOLNyUtY0jpJC0KxNuUYLx+ApcDHQI8MA6uK9pUJoAW4DtwAWiu03Qn0AXeBw/Uwdxz4BnwG9lnSbAv9TwBHbGgnBR0MBHtuSfMqs7lXjUaWn/1HzHUtlCLuTVnSjgZoBwaA+8AWS5obgdFAb5SrHbbGhhEb+B/ljLwp+7Yx5kHBlhwORz0BlhTtoSb8kuWZPx0PAidrFTwAXAM2W/KYJmYT8DW00peBrqyCjcCEL/TEst+kuL0RJQvArbh3KpUoJUmT/vWYNaeVmRtzf15mRWA7cA5YmVmk+phrgJGIHjlULw/WALqAd34CI8D5oj3VBNAMZB9SjoKoqowHGiT1SGqVVJb0UFKfMSbfTZBNgA7gQ8RM8hRYa0F/BdANHCOv0xZgOfApZpECeAxkPiPzExgO6I0CPTZzmA50KSGJaQ5m1G4GvkfojQHr0+qk/Yq7UrTZnTZoiE5JDRH3F0ram1Yk7fycJuFZZQVeCd4ur9R5ZIyZiHhvUYLm4nT2UgJcSTG0ukPvnAC+BJ6/B/ZHaLcwU5gGKQFtthNpwjtdjOMVMD/QvhOv7A4zTsQREnAWmAolcdFqEoFge4ChCHNvgA2htgMJSd+J0d8GXMbr/R3V+qt2QWyUdEq/L4g3jTE/Q+2GJC2LkXlpjEk80P5j8IdaHP1F+0sNcDohkb9nTwEYvH1+8IefBC7kFTPXs1+gQ95CWZbUb4x5kWc8h8PxH/ALOxqWYVqZ6TEAAAAASUVORK5CYII="
    />
  );
};

export const StopButton = props => {
  return (
    <input
      style={{ width: props.size + "px" }}
      onClick={props.action}
      type="image"
      alt="stop"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAADmwAAA5sBPN8HMQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAABWSURBVGiB7c/BCYAwAATBxP57jh0YEAyLzLzvcTsGALw3d4O11jpxZGfO+fj1OnXka0JqhNQIqRFSI6RGSI2QGiE1QmqE1AipEVIjpEZIjZAaIQDwJze7lgREdp+1KwAAAABJRU5ErkJggg=="
    />
  );
};

export const MoreButton = props => {
  return (
    <input
      style={{ width: props.size + "px" }}
      onClick={props.action}
      type="image"
      alt="more"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAADmwAAA5sBPN8HMQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAMOSURBVGiB7ZrLa9RQFMZ/R2u1vqpFkSJaEaq4UBDcqAWpj2LdiBtdCOpKEN34WLQ794roP6ErXSriA5HiSkRFinWqtNSV2IpF8NX6uZhMvE0zM21yM2mlPwgkd5Jzvi83ubk5E5hjZmG+AklqAvYBe4DNQCuwPLLbKFAA+oAnwCMz++JLQ2Ik1Us6JumepDFNnzFJdyUdlVSfh4HFki5K+phAfDmGJJ2X1FArE0ckDXg0EOWDpMNZGlgh6U6GBqLcltTo20SzpFc1NFHihaQ1vky0SCrkYKLEO0nrq+msOPwGAXqAdV7OSnKGgN1mNlRuh7JGJC0CngHbMxCWhOdAm5n9jPtxXoUDr5HOxG+gG9gQLN1BW1J2AFemdYSkTg/XdldM3O6UMf9IOjBVE0slDaa2ITXHxG72EHdA0pJo7LhL6wxQdZSYyjmJafMxt2sBTkcbJxhRcXpwwUMygFMxbSc9xb6k4mAUj6QTHrq+xC9JXZJWB0tX0OaL4652ixh5DLR7OmtZ88DMOkoboREV3yc+AfPzUJWAMWCVmX2FifdIO7PHBEAdztXjGtlZey2pCTXXOY1bfEU3s9hhVlLckJyGULPbI5s8J6kFoWbXyEpf0Uvjo694FWgqrbhGltUgsW/CKk2l2e+swjXyLTcVyRktrbhGRnIQkpZQs2ukLwchaQk1u0be5iAkLaFm18jTHISkJdSc6aQx+oT3/GyJnzSa2Qjw0GOirLlfMgGTnyM3aywmDbfcjWjXLwTeA2t9ZMrw0hoEWs0sLC9N6JGg+HXDU7IsueGagJiqhooFiDfAxqRZyk3jnRxpeqYAbDOzH27jpLmWmX0HzqVIlCUCzkZNVD5Cuu6x4uGLq+X0/v9F7KD7OoDejIRNh17gYDkTUOV9xMw+A4eAfs/CpkMB6DSz4dSRVCw+v8zhnvD315tjZoGky5LGa2BgPMhVV11ZckP7JfVnaKIgaW9mBiJmGjTbPxiIGJoxn3D4/KimEWgDdgFbg6WJfyWbUWCY4vTnNcVnVI+ZjU6ONsfs5y9QKm9ghqfBuAAAAABJRU5ErkJggg=="
    />
  );
};

export const BuyButton = props => {
  return (
    <input
      style={{ width: props.size + "px" }}
      onClick={props.action}
      type="image"
      alt="more"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAABmJLR0QA/wD/AP+gvaeTAAADEklEQVR4nO2cv04VQRSHv9EESNRaTXwDjZQ+AiHBhBgrGqzkEaC0xEfQzkIrCxNj4SPYYTQ+gX96xcClORZ3MYTsws65w9m5y+9LbnGZPWfPfpk7l12GA0IIMV7MbMHMnpvZTzP7YWa7ZrYwdF2joxF7mt2h6xodzUw+za+h6+pLGrqAvpiZtf08pTQX13Bl6AIuCxIdhEQHIdFBSHQQEh2ERAch0UFIdBASHYREB+F+TmBmS8AWsAHcBa6VKqqNrmcaXc9ACvIX+Aq8AV6klCaeJC7RZnYH+ADc98R7GFD0ST4Dayml77mB2aKbmfyJQMlQjWiYyn6QO7M9a/QWwZIrYxl4mhvkEb3hiBkb2Q48S8cf4Hpu3KxUtHQA7KeUbuQEeEQPcWG1ic7+y45+jw5CooOQ6CAkOgiJDkKig5DoICQ6CIkOQqKDkOggJDoIiQ5CooOQ6CAkOgiJDkKig5DoICQ6CIkOQqKDkOgghhR9BOwAt5vXDuDaqdnBpCX/UcH8F0vL/2N72W7JvWpmh20HZ9ZzaGarLcfulCq+tNe+F+bhVkf+FTM76HthLXknZvaw49ibpYrP9VbdGp1S+gisA4eO8CPgcUrpfcf4VXdhMzKk6CddA43sR+St2RNg/QzJAJsZ+Yal1EfPOtbRU+f6v2afU09WrhJ4/fWmVKENnevpifOtmNnBGeO9c5Qs3OuvNyWLbeg1Gz1jx+NWcCYfk+utlv3R532JuTCzFeAdsFQyL8zv/ugF4K2dswTkcJGSPdQiGgrKrk0y1CUaCsiuUTLUJxpmkF2rZKhTNDhk1ywZ6hUNGbJrlwx1i4YesudBMtQvGs6QPS+SoZ4blj5MgGfAq+b9ZvN+cYhicm9Y5kl0VczrneHokeggJDoIiQ5CooOQ6CAkOgiJDsIjer94FfPH79wAj+hvjpixke3AI/q1I2ZsZDvwPOtYZNrJcTk3diTsMe3kmLUzNXtGN60i15i2jrxs7DHtTZq9/XeWbruLTFtHbgD3GKDpYBD7wBem3XZfeiQLIYQQQgghRNX8A8Fno5H58SovAAAAAElFTkSuQmCC"
    />
  );
};

export const VolumeDownIcon = props => {
  return (
    <input
      style={{ width: props.size + "px" }}
      onClick={props.action}
      type="image"
      alt="more"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAADmwAAA5sBPN8HMQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAIOSURBVGiB7Zo/axRBGId/7yXGSNL4Bz+KhWlE4gcQ/AYWVnYKKdJJIIWFYhVbG5GYWKq1H8BKEImI3SVipwmCeSxuAutmb293Zi/vLNzTDOzMvft79uVm5+CkGTP6A2DAA+AbMAS2gPPeuVoBLADbnGbDO1tjgEXgbYUEwJ53vkaETrwZIwHw1zvjREIn3tVIAOCdsxbgQhOJrEXaSGQr0lYiS5EYiexEgsT7thJZiaRIZCOSKjFtkflS2Iul+SMzOwQWJL2SdGuaYZIAloAnwH7FQ7wT1jxO6cRZdETAs5p7Xw1rvuYuYsAPSZfHzA/MDOBYkiXfzMwkCViWtCbpk6RtM/uTWltNnmAX3SjVu1m4/AVY6avIHHA3SAAcAau9EynUnQeeh+khcKmXIoX6H8KS9ViPQewHO2YzjLdjC1jdkyrsMp1snSf1yjDa5oeSfpnZckztXDqSTC4i18L4ObZALiJrYdyNruC5a9H37RcYAPfo+wsRuFG43MkRxYCfksq/Q06Y1qFxUdJDSXvq8NBYd4y/EtZkf4wfaLRjPJV0UDF/PYwvpxnizADOAa9z7khjGB29X/ReREqX8c7/Hyky3tlPESvjnbuSGBnvzGNpK+Odt5Y2Mt5ZJ9JUxjtnIxi9NHdqPH57Z2zMBJmP3vlaEWSq/jBw3ztba8J3Zh34DhwAj4A571wzZkTwDxpTEMCD617JAAAAAElFTkSuQmCC"
    />
  );
};

export const Bandcamp = props => {
  return (
    <input
      style={{ width: props.size + "vh" }}
      onClick={props.action}
      type="image"
      alt="more"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAADmwAAA5sBPN8HMQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAKtSURBVGiB7do5iNVAHMfxj+KteIJHsVqJjVrZeIBXIQiKICpYioWlWFmJhZWIWllYuqCVheKFICKIhSgeuOKBeBaeiBaeq2sxG3nEvN2XTF5eWPzBr3kv+c//O5PJ/DMJ/1UvDSsx1lSsxnLMw1xMTB3zCY/xEFdwCR9LzKGwRmEzzqEXfTndi7PYhJEV5w7GYhdetpBsq36Bnf2xK9EGPC0RIO0nWNdOgEk42UaAtLsxoWyImbhdIUTim5heFsRsPOoAROIH6CoD4kUHIRrnzayiEGOEoe00ROLrGF0E5GgNkk/7SF6INTVIOsu/heqhJU3A8xok3czPMD6d9PAMkB3CJK+r5mD7YAeNwxud7/XB/FqqlEmPyEYlLkBt1AyhVGqqSzrf2636QjOIKYqV4mlvG6inWtDKFtv54d/nHYTLKhbipuwbSKsajjs52lvfeGKixREJJNoj3OuLagsW5jh+adaP58WNxsViuf/VWLzK2eaZrECPIyB+Y1EkyM4C7T7ICvQhAuRYJMQ0YRMib7vvsoJ9LwjxVVhtY3SgYNvfygQ5HAnRhS9lghS5tN5jciRId0GIPrzNClhksu+OhFggbhHOnOxncgZ5ImzQxehyBEQfTiWBGhfETLoBtE8oE4pqFVZEnE+TnNdrvSduqLYUaea1WcHzFI2ZAXJoawkQTYtG4suUKn26MfH05XG8GWENdWKgP0fLX7h1ws+kXkOkR+S7+JW6Ch3Cz8EOGoX7Ot/rzdwjx/o1JDboEh2sQeJp788LwRDaxCY88PTUAOKW8MYsSkPiRU+imUKvVA1xQxt2PkdgL35VANDb39aIsiEatVrcjstgfijsNlaidn4wMKYqiEbV5hOOMj+qmYRlWCI8i88Xbt/JM8NnYYPjHu7iGq72//5fQ05/AMS9uLYmhQ8tAAAAAElFTkSuQmCC"
    />
  );
};

export const VolumeUpIcon = props => {
  return (
    <input
      style={{ width: props.size + "px" }}
      onClick={props.action}
      type="image"
      alt="more"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAADmwAAA5sBPN8HMQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAOUSURBVGiB7ZnLi1RHFId/5WiciTrg28HFqCBk4V6iwYWLQDYBdaGboOA+Kio4oIJoEBEX/gMiySa+EgyC4hOfEMhOiQg+ZuEDH+io6Dia9suiz8Wy5j67b89tYX6bas45Ved8XbfvPXVbGtWovjwBHcAW4DnwBjgITKi6rkICOoETDNeeqmvLLWAicCkGAuBO1fXlku3EqQQIgFrVNWbKduJyCgQArcg9tqyFgC5JxyR9V2DOc0kfJN2VdF3SZUknnXPPyqqrkIAu4HTWToQ7kuAeAg4Di6qAOJMXIgCZDPQAi4GNwEXgPy/0BDBnJCC+Bs4WgfBBEtacDuwBBi18AFjeaohzRSGyQLz1e4HjNuUj0NcKiG7gaiMQeUG8XOuAmk3dVSbEBOB8oxA+CPAYuAEcAVYBExNyrqW+KwAry4DoAi40AxGAhHoKrAe+isn9i8W8BHrTihwH7Ab6YxIss5idzUIEIFOB+dS/cf/OdxWYFdQ3BvjL/H+mgexOyT3NYu6WCRJTw/fAPQvrB3oC/1zgnfnjnzPAg6zEfLpOWwJiOabxqc35G+gM/PvMdzxpgczEZUBkgVieKcBtC98S+GZRv4u9x66UykGoH7guYr/BoJ6lFj4ATA58V8z3U7uA+NoWU1P0wF0T2DeZ/UBkGzOMaGQ1R1Kf6h3wDuCHwH/Ixh8D+zUbFwxbsYod8dbtM9f5wP6N2W8G9plmfxzZnF9kwrcm55zLiimiaD0vd4+kh5JeO+e6PfskSa9i7OMlvZM05JzrlKq/tCK57JB0tQvIahv/CeyzbXwY2KfYOBAZSjvqNiJguqS1knZIQtLeIGSJjf8G9nk29keGSkEkPfE+b3fOnQz8Uacb9lbf2hgCVvYcGST7gfgU6A587fVATBKftyg/B76oRRkCpsZNfpSSO7r91lJiSgHh86bxEjAu8EdN45GkBdLa+BkWczslpmkQhrfxMwO/38YvTFokOlg9icm9wmK2lgkCjAfmUebBKo8M9miJIKHyHHVfkHbUHUkYb61B4Bb5Xz7UgLCBbAqmA/i1WZCcefZ7U9eVBuElaXhncq7fS6tf0AUwx8oEYaRfmTYD480dSzu8xG4UxpsXp4b+Vmj6HODDSPpdUuZl4B3UapKeqV3+6IlE/S7zW94daWvluMzeVl1jbhnMHwkg16uur5AM5nAMyIaqaysswAGbgfvU+6hdQEfVdY1qVAX0P4LSfAfHNqMAAAAAAElFTkSuQmCC"
    />
  );
};

export const SettingsButton = props => {
  return (
    <input
      style={{ width: props.size + "px" }}
      onClick={props.action}
      type="image"
      alt="settings"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAADmwAAA5sBPN8HMQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAP0SURBVGiB7ZrLa11VFIe/lUZr2qa0ClYsAa2pVBN1IGlVBB3oTBCcRCqOxKmIA50otj6giBP/AHEklqpEQXDkAyzFB2iLjyooFZOCrSK11po08X4Ozkm43Nx7zj733JsE7A8OXNhrr7V++7H2XmtfuIj/AdQD6rzLMac+sdr+JUM92YbEIo72w2b0WqG6FfijQGQOGI6I+V7aHeilshy7StrXAzt7bTSZiDqiPqZuKxEtIwIwXmLrqtzWSKp/SVDH1FP5Gj+jPlgg+3LB/ljECwX9J3Mbqr+qY70isVOdaXGkob6iXtYiu1X9JIHIR+qWlr6X5oPQaJGdVkfrkri+DYlmHDObrdvVKbPwmopZ9ZB6kzqqflEgO60W7quOUctsfX4KXF3CdwEYrD5MS2iQRbKhErmTwG0RMdOusWiz30M5CahHYtGHMhIA24F7OzUWzch64HPg5squ9QffA7dGxPl2jR1nJCLmgL3AbJ8cq4ILwEOdSEDJORIR3wLP9NqrLvBsRHxZS4M6oH5YIRr1Gh+opQd30l1L3UG2Ri+pNSrVcR4Yj4gTZYKpV5RrWHkSkEWzTSmCqUQer+jAaWA/sBvYln+7geeA3yroiS5st4d6pe2TpE54Xd1YoG+TerCCvrPqhhTGrYY2A2NkN9Qx4A5gIpH3m8BkRFgkpAbwFvBAot7DZGfad8DXwPGI+KuT8hfVnyuMVCtO54OQBHWL+nsNeydsd4tWF2ooVa183qj7atpcyjKbN3vdbPHdFerTjHWLP5b2iNqgXg4/HBHnqnRQh4GzNWwaEQPQn5x9VdBLIju66FMv82tCM5GFmrruX6E+zVjus/qU+pXV0tVmrGT4nc19fXJRX7sDcZBsysfz7y7g7kT/+nUgvg8cITsQvwF+ioh/E/suGV3tK8qftlRruob6XgXDmtXA9qkT6ob8m1D3my3BKngtxcfUfGQPWUVlpdEAboyIH8oEU8Pv39SPat1gDkjaC6VE1CHgEPXLPt1gCJjKfShEyoy8BNxQ26XuMQ4cqKVBvc/ltdjVQEMtPDyLCnRXAMfIKnxrAaeAWyLiVLvGoqX1MGuHBGR5/95OjUVEpsgKx2WoG80aZGWfMswAb3dlwextZLpg7X5m9vRwp/qO1e5p59RX1V3qterhAtmevJGMtiEzb3ZKD7bIXq4eSSDxsVlS1dx3nfq0eqFF9hf1ulokmoyMqD/mio+qHd8ATXt6e76g/1huw9xmz98Rt6uPWnAhzOUeSSAyWaJjY25r9YKNuieBSMrLbyX04w8Dm4EzBbr/IStUVMsnStDz4kNEnCULlZ1wvNckoH9VlINAuyyxAbzRJ5sXsabwH4krrjNku6u2AAAAAElFTkSuQmCC"
    />
  );
};

export const DiscordButton = props => {
  return (
    <input
      style={{ width: props.size + "px" }}
      onClick={props.action}
      type="image"
      alt="settings"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAABuVBMVEVHcEz///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+86m99AAAAknRSTlMAAgH40vnRBsf0MhfQT+wHFjOT7ghOTXDz/EzV6eOVzhPalBGbZsjNMcYw3xXdNqGc1D6eUs9XXgP+dmkq9kD9LBg/2ZK0BL8bY33yJnjE4lENpqQoCa7xeuGj5oRLsvUSt3kc73OCSXK1d94a6O0pCi7YLaqrOqL6wfvTwz3CZV/lWK/r5LBTRkfJwMqREJArNB0yzV0AAAIUSURBVEjH7ZXXV9swGMWVEMdxgIQkhBCyQ8Ios+y9NwmbQsvqYnQCLdA9oHu3319cR7KCFSvnmBceONwHH9376WdJtiUjdKnzViIeC03+MFJrHJ0MxeKJ7P3HJiIAIEz9KqXJ7z9TghwNToxxgYJoDkDO3MwsG8/OzKXyYIGWKPEDuJJhudU37hz2ubxel2/YOf5XDsJJF4C/JJM4eg7glCf0LzgNjKajDoRKo/JAtSzR5gN4KaJvP4Gj71+R6AVoNjBIa6o0+hay6PWL1LWFQUygQyE10QC6pF7NDX1I/SlRuKQPuWtPI02gU0VpZF4vUkcJg18v8oh+sFbQrX0Fua8OK6/YKyxck9KmgqyrsjxRDkQzx2BdU5AVzTPJ5xisACHuqEcm26KaY4ge4sitjnJxlMsxRGTX7Kgjt3oubs3EwIOjIGiWb+IYIidGbjFP3mItdJRxDdY9jFyFM8iEketnQaow8piY9ube7D17ze2kYcOIQEwMDUSyEZEeVE9aAkbKiXnVj0biAR4QeDeC+pUVl2NkVyLuY6sR2b98ltj+xR+aRGR8b1PcAvli1mgvU4t8VIk9HY2WGpsg2GosjR0D8qtpO6Gvpnib7jFHFb3np6E37AGHDA1Dx7T6wHqa761u0Li7i0W6umlF2lpkKuHlQVLozDytO0n+5PZN7dn/1HNoluq0+TPJfODpu/xdX0D9Bx/mR28UxUIHAAAAAElFTkSuQmCC"
    />
  );
};

export const FacebookButton = props => {
  return (
    <input
      style={{ width: props.size + "px" }}
      onClick={props.action}
      type="image"
      alt="settings"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAgVBMVEVHcEz///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8KTOKkAAAAKnRSTlMAR7jMGnbDwtXubw0St/qd4GTq+dp4/pzfxbDz1PjZd78fJPIPlfzdGYBLDbDQAAAAk0lEQVRIx+3VSQ+CMBCG4aqlgID7grIJLuD8/x/oCY1Nms53MB7a99xn0qSTVIj/1gbZqqB405ezy54DbssFfXowRFfTV3aRRISSO6FEVjCZE0zOONnpIrSSeDy6DSTz4Q8jadi78r7QgBPhFDmRodA+Xe+KkwgnU5wccfLEyRonEyNRBpGnfpM98cQFoti/6u96AaD5ZNZGOp05AAAAAElFTkSuQmCC"
    />
  );
};
