import React, { useContext } from "react";
import { SharedState } from "../App";
import { PreviouslyPlayedSingle } from "./PreviouslyPlayedSingle";

export const PreviouslyPlayed = () => {
  const { metadata } = useContext(SharedState);
  const { history } = metadata;
  return history ? (
    <React.Fragment>
      {history[1] ? <PreviouslyPlayedSingle number={1} /> : null}
      {history[2] ? <PreviouslyPlayedSingle number={2} /> : null}
      {history[3] ? <PreviouslyPlayedSingle number={3} /> : null}
      {history[4] ? <PreviouslyPlayedSingle number={4} /> : null}
    </React.Fragment>
  ) : null;
};
