import React from "react";
import { PlaybackControl } from "./PlaybackControl";
import { VolumeSlider } from "./VolumeSlider";
import { More } from "./More";
import { Settings } from "./Settings";

export function Controls(props) {
  return (
    <div className="control">
      <PlaybackControl />
      <VolumeSlider />
      <google-cast-launcher
        style={{ maxHeight: "5vh", display: "block" }}
      ></google-cast-launcher>
      <More number={0} />
      <Settings />
    </div>
  );
}
