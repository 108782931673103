import React, { useContext } from "react";
import { SharedState } from "../App";

export const AlbumArt = (props) => {
  const { metadata } = useContext(SharedState);
  const covers = metadata.covers;

  return (
    <img
      alt="Album Artwork"
      src={
        covers
          ? covers[props.size]
          : "https://res.cloudinary.com/stormdragon-designs/image/upload/c_scale,w_" +
            props.size +
            "/v1526676139/fricon_600-600_zersgq.png"
      }
    />
  );
};
