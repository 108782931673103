import React, { useContext } from "react";
import { SharedState } from "../App";
import { MobileViewHome, Cont, ShowInfo } from "./MobileView";
import {
  BrowserView,
  isMobile,
  ConsoleView,
  TabletView,
  MobileOnlyView,
} from "react-device-detect";
import { Controls } from "./Controls";
import { NowPlayingDesktop } from "./NowPlayingDesktop";
import { PreviouslyPlayed } from "./PreviouslyPlayed";
import { AlbumArt } from "./AlbumArt";
import { LivePanel } from "./LivePanel";
import { Nav } from "./Nav";

export function HomePage(props) {
  const { metadata } = useContext(SharedState);
  const { showInfo } = useContext(SharedState);
  const { isLive } = metadata;
  return (
    <div
      className={`homegrid ${isLive ? `live` : null} ${
        isMobile ? `mobile` : null
      }`}
    >
      <BrowserView renderWithFragment>
        <div className="logo" />
        <Nav />
        <div
          className="art"
          style={{
            backgroundImage: `url(${
              metadata.covers
                ? metadata.covers[512]
                : "https://res.cloudinary.com/stormdragon-designs/image/upload/v1526676139/fricon_600-600_zersgq.png"
            })`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* <AlbumArt size={512} /> */}
        </div>
        <NowPlayingDesktop />
        <PreviouslyPlayed />
        <Controls />
        {isLive ? <LivePanel /> : null}
      </BrowserView>
      <ConsoleView renderWithFragment>
        <div className="logo" />
        <Nav />
        <div
          className="art"
          style={{
            backgroundImage: `url(${
              metadata.covers
                ? metadata.covers[512]
                : "https://res.cloudinary.com/stormdragon-designs/image/upload/v1526676139/fricon_600-600_zersgq.png"
            })`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* <AlbumArt size={512} /> */}
        </div>
        <NowPlayingDesktop />
        <PreviouslyPlayed />
        <Controls />
        {isLive ? <LivePanel /> : null}
      </ConsoleView>
      <MobileOnlyView renderWithFragment>
        <MobileViewHome />
      </MobileOnlyView>
      <TabletView renderWithFragment>
        <div className="logo" />
        <div className="art">
          <AlbumArt size={512} />
        </div>
        <NowPlayingDesktop />
        <PreviouslyPlayed />
        <Controls />
        <Cont />
        {showInfo ? <ShowInfo /> : null}
      </TabletView>
    </div>
  );
}
